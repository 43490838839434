import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom'; // Импорт из react-router-dom
import { Link as ScrollLink, Element } from 'react-scroll';
import axios from 'axios';
import PayProductList from './PayProductList/PayProductList';
import { api_key_NP, inputs, payType, homeDevInputs } from './payment-help';
import { useMediaQuery } from 'react-responsive';
import './Payment.css';

export default function Payment() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [formData, setFormData] = useState({
    lastName: '',
    firstName: '',
    phone: '',
    email: '',
  });

  const [items, setItems] = useState([]);

  const [sum, setSum] = useState(0);

  const [formDeliveryData, setFormDeliveryData] = useState({
    city: '',
    region: '',
    street: '',
    appart: '',
  });

  const [deliveryDev, setDeliveryDev] = useState(null);
  const [paymentDev, setPaymentDev] = useState(null);

  const [inputFlag, setInputFlag] = useState(false);

  const [currentDate, setCurrentDate] = useState('');
  const [stringDate, setStringDate] = useState('');

  const [wfpData, setWfpData] = useState(null);
  const [formHTML, setFormHTML] = useState(''); //запис строки юрл для переходу на wfp

  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [warehouse, setWarehouse] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  // eslint-disable-next-line
  const [selectedCityRef, setSelectedCityRef] = useState('');
  const [allWarehouses, setAllWarehouses] = useState([]);

  const navigate = useNavigate();

  // счатем сумму всех товаров

  useEffect(() => {
    let total = items.reduce((acc, item) => acc + item.sum, 0);

    if (total % 1 === 0) {
      total = total.toFixed(0);
    } else {
      total = total.toFixed(6);
    }
    // Преобразуем обратно в число
    total = parseFloat(total);

    setSum(total);
  }, [items]);

  // получение данных из локала
  useEffect(() => {
    const atomizer = localStorage.getItem('atomizerItems');
    if (atomizer) {
      setItems(JSON.parse(atomizer));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDeliveryChange = (e) => {
    const { name, value } = e.target;
    setFormDeliveryData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // -------------------------формуємо айді з дати-------------------------
  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
    const seconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
    const paddedMonth = month < 10 ? `0${month}` : month;
    const paddedDay = day < 10 ? `0${day}` : day;

    setCurrentDate(`${month}_${day}_${hours}:${minutes}:${seconds}`);
    setStringDate(`${year}${paddedMonth}${paddedDay}`);
  }, []);

  //-----------------------------------------NP API-------------------------------------------
  useEffect(() => {
    setWarehouse('');
    setWarehouses([]);
  }, [city]);

  const handleCityChange = async (e) => {
    const query = e.target.value;
    setCity(query);

    if (query.length > 1) {
      const response = await axios.post('https://api.novaposhta.ua/v2.0/json/', {
        apiKey: api_key_NP,
        modelName: 'Address',
        calledMethod: 'getCities',
        methodProperties: {
          FindByString: query,
        },
      });

      setCities(response.data.data);
    } else {
      setCities([]);
    }
  };

  const handleCitySelect = (city) => {
    setCity(city.Description);
    setSelectedCityRef(city.Ref);
    setCities([]);
    fetchWarehouses(city.Ref);
  };

  const fetchWarehouses = async (cityRef) => {
    const response = await axios.post('https://api.novaposhta.ua/v2.0/json/', {
      apiKey: api_key_NP,
      modelName: 'Address',
      calledMethod: 'getWarehouses',
      methodProperties: {
        CityRef: cityRef,
      },
    });

    setWarehouses(response.data.data);
    setAllWarehouses(response.data.data); // Зберігаємо всі відділення
  };

  const filterWarehouses = (inputText) => {
    const filtered = allWarehouses.filter((warehouse) => warehouse.Description.toLowerCase().includes(inputText.toLowerCase()));
    setWarehouses(filtered);
  };

  const handleWarehouseSelect = (warehouse) => {
    setWarehouse(warehouse.Description);
    setWarehouses([]);
  };

  // -----------------------------------WFP------------------------

  const apiWayforpay = async (data) => {
    try {
      const response = await axios.post('https://atomizer-backend.onrender.com/wayforpay', data);
      setFormHTML(response.data);
    } catch (error) {
      // console.error('Error posting data:', error);
    }
  };

  useEffect(() => {
    let productName = '';
    if (items) {
      items.forEach((item, index) => {
        const name = `Флакон для парфумів ${item.category === 'thin' ? 'тонке' : 'товсте'} скло ${item.ml} мл, ${
          item.color === 'black' ? 'чорна' : item.color === 'silver' ? 'срібна' : 'золота'
        } кришка. Кількість ${item.quantity}`;
        if (index > 0) {
          productName += ', ';
        }
        productName += name;
      });
    }

    setWfpData({
      result_url: 'https://atomizer-b07a9.web.app/',
      amount: sum.toString(),
      currency: 'UAH',
      orderReference: currentDate,
      language: 'ua',
      server_url: 'https://atomizer-backend.onrender.com/wfp-callback',
      orderDate: stringDate,
      productPrice: sum.toString(),
      productName,
      productCount: 1,
    });
    // eslint-disable-next-line
  }, [items, sum]);

  // ---------------------telegram data----------
  const formedTelegramData = () => {
    let productList = [];
    let recipient = '';
    if (items) {
      items.forEach((item) => {
        productList.push(
          `${productList.length + 1}й. назва: ${item.category === 'thin' ? 'Тонке скло' : 'Товсте скло'}, кількість: ${item.quantity} шт, колір: ${
            item.color === 'black' ? 'чорний' : item.color === 'silver' ? 'срібний' : 'золотий'
          }, розмір: ${item.ml} мл, ціна: ${item.price} грн`,
        );
      });
    }
    if (deliveryDev === 1) {
      recipient = `Відділення НП - ${city}, ${warehouse} `;
    } else {
      recipient = `Адресна - місто: ${formDeliveryData.city}, область: ${formDeliveryData.region},вулиця: ${formDeliveryData.street},${formDeliveryData.appart}`;
    }

    const productNames = productList.join('\n');

    const payment = paymentDev === 1 ? 'Оплата онлайн' : 'Переказ на карту';
    return {
      'Номер замовлення': currentDate,
      'Метод оплати': payment,
      'Статус оплати': 'непідтверджено',
      'Загальна сума': `${sum} грн`,
      '-=-=-=-=-=-=-': '=-=-=-=-=-=-=-',
      Користувач: formData.lastName + ' ' + formData.firstName,
      Телефон: formData.phone,
      email: formData.email,
      '=-=-=-=-=-=-': '=-=-=-=-=-=-',
      Доставка: recipient,
      '-=-=-=-=-=-=': '-=-=-=-=-=-',
      Товар: productNames,
    };
  };

  //-------------------------------------------------------------------------------
  const handleSubmit = async () => {
    // if (formData.lastName && formData.firstName && formData.phone && deliveryDev && paymentDev) {
    //   setInputFlag(false);
    // } else {
    //   setInputFlag(true);
    //   return;
    // }
    // const telegramData = formedTelegramData();
    // await axios.post('https://atomizer-backend.onrender.com/telegram-message', telegramData);
    // if (paymentDev === 1) {
    //   setTimeout(() => {
    //     window.location.href = formHTML;
    //   }, 200);
    // } else {
    //   navigate('/success');
    // }
  };

  return (
    <article className="payment">
      <section className="payment__left">
        <Element name="section1">
          <h1 className="payment__left-title">Оформлення замовлення</h1>
        </Element>
        <form className="payment__left-form">
          <p className="payment__left-form-title">Введіть, будь ласка, ваші контактні данні:</p>
          <ul className="payment__left-form-list">
            {inputs.map((input) => (
              <li key={input.name} className="payment__left-form-item">
                <input
                  className={`payment__left-form-input ${inputFlag ? 'payment__left-form-input--disable' : ''}`}
                  id={input.name}
                  placeholder={input.label}
                  name={input.name}
                  type={input.type}
                  value={formData[input.name]}
                  onChange={handleChange}
                  required
                />
              </li>
            ))}
          </ul>
        </form>
        <div className="payment__left-delivery-wrap">
          <p className="payment__left-form-title">Оберіть спосіб доставки:</p>
          <div
            className={`payment__left-list-item payment__left-delivery-item ${inputFlag && !deliveryDev ? 'payment__left-delivery-item--disactive' : ''}`}
            onClick={() => {
              setDeliveryDev(1);
            }}
          >
            <p className="payment__left-list-item-title">Самовивіз з відділення Нової Пошти</p>
            <p className="payment__left-list-item-text">(оплата за тарифами перевізника)</p>
            {deliveryDev === 1 && (
              <>
                <div className="payment__left-np-input-wrap">
                  <input type="text" value={city} onChange={handleCityChange} placeholder="Введіть місто" required className="payment__left-np-input" />
                  {cities.length > 0 && (
                    <div className="payment__np-list--wrap">
                      <ul className="payment__np-list">
                        {cities.map((city) => (
                          <li key={city.Ref} onClick={() => handleCitySelect(city)} className="payment__np-list-item">
                            {city.Description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="payment__left-np-input-wrap">
                  <input
                    type="text"
                    value={warehouse}
                    onChange={(e) => {
                      setWarehouse(e.target.value);
                      filterWarehouses(e.target.value);
                    }}
                    placeholder="Введіть відділення"
                    className="payment__left-np-input"
                    required
                  />
                  {warehouses.length > 0 && (
                    <div className="payment__np-list--wrap">
                      <ul className="payment__np-list payment__np-list--warehouse">
                        {warehouses.map((warehouse) => (
                          <li key={warehouse.Ref} onClick={() => handleWarehouseSelect(warehouse)} className="payment__np-list-item">
                            {warehouse.Description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            className={`payment__left-list-item ${inputFlag && !deliveryDev ? 'payment__left-delivery-item--disactive' : ''}`}
            onClick={() => {
              setDeliveryDev(2);
            }}
          >
            <p className="payment__left-list-item-title">Адресна доставка Новою Поштою</p>
            <p className="payment__left-list-item-text">(оплата за тарифами перевізника)</p>
            {deliveryDev === 2 && (
              <form className="payment__left-delivery-form">
                <ul className="payment__left-form-list">
                  {homeDevInputs.map((input) => (
                    <li key={input.name} className="payment__left-form-item">
                      <input
                        className="payment__left-form-input"
                        id={input.name}
                        placeholder={input.label}
                        name={input.name}
                        type={input.type}
                        value={formDeliveryData[input.name]}
                        onChange={handleDeliveryChange}
                        required
                      />
                    </li>
                  ))}
                </ul>
              </form>
            )}
          </div>
        </div>
        <div className="payment__left-payType">
          <Element name="section2">
            <p className="payment__left-form-title">Спосіб оплати:</p>
          </Element>
          <ul className="payment__left-list">
            {payType.map((btn) => {
              return (
                <li
                  key={btn.index}
                  onClick={() => {
                    setPaymentDev(btn.index);
                    apiWayforpay(wfpData);
                  }}
                  className={`payment__left-list-item payment__left-payType-list-item ${paymentDev === btn.index && 'payment__left-payType-list-item--active'} ${
                    inputFlag && !paymentDev ? 'payment__left-payType-list-item--disactive' : ''
                  }`}
                >
                  <p className="payment__left-list-item-title">{btn.title}</p>
                  <p className="payment__left-list-item-text">{btn.text}</p>
                </li>
              );
            })}
          </ul>

          {paymentDev === 2 && (
            <div className="payment__left-list-item">
              <p className="payment__left-list-item-title">Найменування отримувача: ТОВ КАЙРОС-М</p>
              <p className="payment__left-list-item-title">Код отримувача: 45187884</p>
              <p className="payment__left-list-item-title">Рахунок отримувача: UA933133990000026003000204787</p>
              <p className="payment__left-list-item-title big-text">після переказу натисніть підтвердити замовлення</p>
            </div>
          )}
        </div>
      </section>
      <section className="payment__right">
        {items.length > 0 ? (
          <PayProductList list={items} setItems={setItems} />
        ) : (
          <div className="payment__right-empty-basket">
            <p className="payment__right-empty-basket-text">Упс...</p>
            <p className="payment__right-empty-basket-text">Схоже, ви ще не зробили свій вибір</p>
            <div className="payment__right-empty-basket-icon">
              <img src="/img/icons/ion_cart.svg" alt="empty-catr" />
            </div>
          </div>
        )}
        <div className="payment__right-info">
          <div className="payment__right-info-wrap">
            <p className="payment__right-info-price-text">До сплати</p>
            <p className="payment__right-info-price">{sum} ГРН</p>
          </div>

          {isMobile ? (
            formData.lastName && formData.firstName && formData.phone && deliveryDev && paymentDev ? (
              <button onClick={handleSubmit} className="payment__right-info-submitBtn" disabled={items.length === 0}>
                Підтвердити замовлення
              </button>
            ) : (
              <ScrollLink to={!formData.lastName || !formData.firstName || !formData.phone ? 'section1' : 'section2'} smooth={true} duration={500}>
                <button onClick={handleSubmit} className="payment__right-info-submitBtn" disabled={items.length === 0}>
                  Підтвердити замовлення
                </button>
              </ScrollLink>
            )
          ) : (
            <button onClick={handleSubmit} className="payment__right-info-submitBtn" disabled={items.length === 0}>
              Підтвердити замовлення
            </button>
          )}

          <p className="payment__right-info-text">
            Підтверджуючи замовлення, я приймаю умови{' '}
            <RouterLink to="/privacy-policy" className="payment__right-info-text-link">
              політики конфіденційності
            </RouterLink>{' '}
            та{' '}
            <RouterLink to="/privacy-policy" className="payment__right-info-text-link">
              угоди користувача
            </RouterLink>
          </p>
        </div>
      </section>
    </article>
  );
}
